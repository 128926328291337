<template>
  <div class="layout-terminal" v-if="offer.length">
    <div class="main-wrapper">
      <div class="main-area">
        <Modal
          :showFooter="true"
          class="events-container-terminal"
          width="100%"
          height="100%"
          v-if="showModal"
          @closeModal="closeDetails"
          @closeModalOutside="closeDetails('outside')"
          ref="modal">
          <template #header>
            <div class="info">
              <div class="product-info">{{ productName }}</div>
              <div class="round-info"><span>{{ roundLabel }}
                </span>{{ currentScheduleRound }}</div>
              <i class="icon icon-close-a"
                @click="closeDetails" />
            </div>
          </template>
          <template #body>
            <Tabs :tabs="tabComponents"
              :activeTab="activeTab"
              v-bind="$attrs"
              @change="updateTab"></Tabs>
            <component v-bind:is="component.component"
              :statsData="statistics"
              :resultsData="results"
              :colors="labelColors"
              :roundLabel="roundLabel"
              :racers="racers"/>
          </template>
        </Modal>
        <TopBar />
        <BettingAreaTerminal />
      </div>
    </div>
    <div class="betslip-area">
      <BetslipArea :class="{ show: showBetslip }"/>
    </div>
  </div>
</template>

<script>
import { startCase } from 'lodash';
import { mapGetters } from 'vuex';
import { eventBus, Modal } from '@nsftx/games-sdk-js';
import Tabs from '@/components/Terminal/TabsTerminal';
import Statistics from '@/components/Terminal/RacesEventsStatisticsATerminal';
import Results from '@/components/Terminal/RacesEventsResultsATerminal';
import TopBar from '@/components/Terminal/TopBar';
import BetslipArea from '@/components/Terminal/BetslipArea';
import BettingAreaTerminal from '@/components/Terminal/BettingAreaTerminal';

export default {
  name: 'Terminal',
  components: {
    TopBar,
    BetslipArea,
    BettingAreaTerminal,
    Modal,
    Tabs,
    Statistics,
    Results,
  },
  data() {
    return {
      showModal: false,
      showBetslip: false,
      tabComponents: [],
      activeTab: {},
    };
  },
  computed: {
    ...mapGetters([
      'offer',
      'config',
      'gameState',
      'translations',
      'labelColors',
      'racers',
      'currentScheduleRound',
    ]),
    statistics() {
      return this.gameState.statistics;
    },
    results() {
      return this.gameState.previousResults;
    },
    productName() {
      return startCase(this.config.productName);
    },
    roundLabel() {
      return this.translations.general_round;
    },
    components() {
      return [
        {
          id: 0,
          key: 0,
          name: 'Statistics',
          label: this.translations.general_statistics,
          component: Statistics,
          disabled: false,
        },
        {
          id: 1,
          key: 1,
          name: 'Results',
          label: this.translations.general_results,
          component: Results,
          disabled: false,
        },
      ];
    },
    component() {
      return this.activeTab;
    },
  },
  methods: {
    closeDetails(option) {
      if (option === 'outside') {
        this.showModal = false;
      } else {
        this.$refs.modal.closeModal('outside');
        setTimeout(() => {
          this.showModal = false;
        }, 500);
      }
    },
    updateTab(tab) {
      this.activeTab = tab;
    },
  },
  created() {
    this.tabComponents = this.components;
  },
  mounted() {
    eventBus.$on('toggleEventsAreaModal', () => {
      this.showModal = !this.showModal;
    });
    this.$root.$on('ShowBetslipTerminal', () => {
      this.showBetslip = true;
      this.$nextTick(() => {
        eventBus.$emit('BetslipOpened');
      });
    });
    eventBus.$on('MinimizeBetslip', () => {
      this.showBetslip = false;
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-back.events-container-terminal {
      width: 100%;
      margin: 0 auto;
      background-color: var(--overlay-lock);
      top: 0px;
      position: absolute;
      height: 100%;
      border-radius: 3px;
      .modal {
        max-width: 100%;
        position: absolute;
        background-color: var(--card);
        padding: 0;
        height: 100%;
        .modal-header {
          background-color: var(--button);
          height: 100px;
          .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;
            .product-info {
              color: var(--text-primary-1);
              font-size: 22px;
              line-height: 26px;
              font-weight: 500;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .round-info {
              color: var(--text-primary-1);
              font-size: 14px;
              line-height: 18px;
              margin-top: 10px;
              font-weight: bold;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .icon {
              position: absolute;
              right: 16px;
              top: 19px;
              color: var(--text-primary-1);
              cursor: pointer;
              margin-left: auto;
              font-size: 18px;
            }
          }
        }
        .modal-body {
          overflow-y: scroll;
          .tabs {
            font-size: 16px;
            .empty {
              width: 100%;
            }
          }
          .statistics {
            .desktop {
              .wrap-top {
                display: flex;
                .field-group {
                  .statistics-group-field {
                    .title {
                      font-size: 16px;
                    }
                    .content {
                      .field-wrap {
                        .field {
                          width: 56px;
                          height: 50px;
                          line-height: 50px;
                          font-size: 18px;
                        }
                        .label {
                          font-size: 16px;
                        }
                      }
                    }
                  }
                }
              }
              .wrap-main {
                display: flex;
                justify-content: space-around;
                .wrap-left,
                .wrap-right {
                  max-width: 330px;
                  .label {
                    font-size: 16px;
                  }
                }
              }
            }
          }
          .results {
            .wrap-left {
              width: 60%;
              display: inline-block;
            }
            .wrap-right {
              width: 35%;
              display: inline-block;
              .results-group {
                margin-top: 20px;
                padding-left: 20px;
              }
            }
          }
        }
        .modal-footer {
          display: none;
          padding-top: 0px;
        }
      }
    }
.layout-terminal {
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 0;
  }
  .main-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    .main-area {
      position: relative;
      background-color: var(--background-1);
      padding: 8px;
    }
    .events-container-terminal {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
@media all and (min-width: 1014px) and (max-width: 1259px) {
  .layout-terminal {
    .main-wrapper {
      .main-area {
        width: 1014px;
      }
    }
    .betslip-area {
      .betslip-area {
        display: none;
        position: absolute;
        top: 0;
        width: 356px;
        right: 0;
        &.show {
          display: block;
        }
        ::v-deep .betslipDWrapper {
          .predefinedStakesWrapper {
            .bottom {
              .predefinedStakes {
                .predefinedStake {
                  min-width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media all and (min-width: 1260px) and (max-width: 1579px) {
  .layout-terminal {
    .main-wrapper {
      .main-area {
        width: 924px;
      }
    }
    .betslip-area {
      width: 356px;
      ::v-deep .betslipDWrapper {
        .predefinedStakesWrapper {
          .bottom {
            .predefinedStakes {
              .predefinedStake {
                min-width: 100px;
              }
            }
          }
        }
      }
    }
  }
}
@media all and (min-width: 1580px) and (max-width: 1909px){
  .layout-terminal {
    .main-wrapper {
      .main-area {
        width: 1020px;
      }
    }
    .betslip-area {
      width: 400px;
    }
  }
}
@media all and (min-width: 1910px) {
  .layout-terminal {
    .main-wrapper {
      .main-area {
        width: 1456px;
      }
    }
    .betslip-area {
      width: 400px;
    }
  }
}
</style>
